import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import one from "../img/company_logo/shape_one.png";
import two from "../img/shape_two.webp";
import three from "../img/shape_three.webp";
import png1 from "../img/png1.png";
import png2 from "../img/png2.png";
import png3 from "../img/png3.png";
import png4 from "../img/png4.png";
import png5 from "../img/png5.png";
import png6 from "../img/png6.png";
import logo1 from "../img/Dedicated.png";
import logo2 from "../img/Timely.png";
import logo3 from "../img/Support.png";
import About_bg from "../img/background_img/About-Us.webp";

// import About_bg_1 from "../img/banner/about_mob_view_002.jpg";
import Helmet from "react-helmet";
import dub_img from "../img/bn3256.png";
import logo from "../img/logo192.png";

import ecommerce from "../img/services/01.svg";
import wordpress from "../img/services/01-svg (1).webp";
import api from "../img/services/03.svg";
import website from "../img/services/04.svg";
import webgraphic from "../img/services/05.svg";
import php from "../img/services/02-svg.webp";
import crm from "../img/services/07.svg";
import mobile from "../img/services/08.svg";

const About = () => {
  const [percentage, setPercentage] = useState(0);
  const [percentage1, setPercentage1] = useState(0);
  const [percentage2, setPercentage2] = useState(0);

  const [kk, setkk] = useState(true);
  const [kk2, setkk2] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (percentage < 92) {
        setPercentage(percentage + 1);
      }
      if (percentage1 < 286) {
        setPercentage1(percentage1 + 1);
      }
      if (percentage2 < 672) {
        setPercentage2(percentage2 + 1);
      }
    }, 50);
  }, [percentage, percentage1, percentage2]);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Discover AjivaInfotech's Visionary Tech Solutions"
        />
        <title>Discover AjivaInfotech's Visionary Tech Solutions</title>
        <meta name="robots" content="index, follow"></meta>
        <meta
          content="Learn about AjivaInfotech's commitment to delivering innovative tech solutions. Discover how our team's expertise can help your business succeed"
          name="description"
        ></meta>
        <meta
          name="description"
          content="Learn about AjivaInfotech's commitment to delivering innovative tech solutions. Discover how our team's expertise can help your business succeed"
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <section className="container-fluid ">
        <div className="m-auto">
          <div>
            <div className="about_us w-full">
              <img
                className="w-full"
                src={About_bg}
                alt="About_bg"
                // className="lg:block md:block hidden"
              />
              {/* <img
          src={About_bg_1}
          alt="About_bg"
          className="lg:hidden md:hidden block"
        /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid">
        <div className="w-full">
          <div className="container m-auto">
            <div className=" px-4 md:pt-8 lg:pt-8 pt-4">
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-4">
                <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
                  <img
                    src={dub_img}
                    alt="dub_img"
                    className="absolute inset-0 h-full w-full object-contain"
                  />
                </div>

                <div className="lg:py-10 md:pb-4 pb-4 border-b-2 border-dotted border-gray-300">
                  <h2 className="text-red-600 text-xl font-semibold flex">
                    <p className="h-1 my-3 mr-4 bar"></p>
                    ABOUT US
                  </h2>
                  <h2 className="text-2xl font-bold sm:text-3xl text-[#343434] mb-5 mt-1">
                    GROW YOUR AUDIENCE
                  </h2>

                  <p className="mt-5 text-[#4B4B4B] text-justify">
                    <span className="font-bold text-red-600 mr-1">
                      Ajiva Infotech Pvt Ltd{" "}
                    </span>
                    is a software development company driven by a passion for
                    innovation and excellence. Founded on July 17, 2019, in
                    Mohali, Punjab, we have consistently aimed to redefine the
                    digital landscape by pushing the boundaries of creativity
                    and technology.
                  </p>
                  <p className="mt-4 text-[#4B4B4B] text-justify">
                    We specialize in creating high-quality business websites
                    that reflect a seamless fusion of creativity and
                    functionality. Our team comprises not only proficient
                    developers but also strategic partners dedicated to
                    maximizing your brand's potential and steering it toward
                    unprecedented success.
                  </p>
                  <p className="mt-4 text-[#4B4B4B] text-justify">
                    We take pride in our commitment to delivering top-notch
                    software solutions that cater to your unique business needs.
                    At Ajiva Infotech, we emphasize collaboration and
                    understanding our client's objectives, ensuring that every
                    project we undertake is a tailored journey toward achieving
                    excellence in the digital realm.
                  </p>
                </div>
              </div>

              <div className="py-8 bg-[#FBFBFB]">
                <ul className="flex">
                  <li
                    className={
                      kk
                        ? "py-2 px-4 md:py-2 w-1/2 sm:w-auto text-center border-t-2 border-r-2 border-l-2 border-gray-300 cursor-pointer  text-sm md:text-md lg:text-lg bg-red-600 text-white font-bold"
                        : "py-2 px-4 md:py-2 w-1/2 sm:w-auto text-center cursor-pointer text-sm md:text-md lg:text-lg bg-[#fff]  font-medium"
                    }
                    onClick={() => {
                      setkk(true);
                      setkk2(false);
                    }}
                  >
                    OUR VISION
                  </li>
                  <li
                    className={
                      kk2
                        ? "py-2 px-4 md:py-2 w-1/2 sm:w-auto text-center border-t-2 border-r-2 border-l-2 border-gray-300 cursor-pointer text-sm md:text-md lg:text-lg bg-red-600 text-white font-bold"
                        : "py-2 px-4 md:py-2 w-1/2 sm:w-auto text-center  cursor-pointer text-sm md:text-md lg:text-lg bg-white  font-medium"
                    }
                    onClick={() => {
                      setkk(false);
                      setkk2(true);
                    }}
                  >
                    OUR MISSION
                  </li>
                </ul>
                <div className="w-full border-2 p-5 rounded-md rounded-t-none bg-[#fff]">
                  {kk ? (
                    <ul>
                      <li>
                        <p className="text-justify font-light text-md md:text-md lg:text-normal tracking-wide">
                          <span className="font-bold text-[#3c3c3c] tracking-wide">
                            Innovative Excellence:
                          </span>{" "}
                          To be a pioneer in software development, consistently
                          delivering cutting-edge solutions that redefine
                          industry standards.
                        </p>
                      </li>
                      <li>
                        <p className="my-1 lg:my-3 text-justify font-light tracking-wide">
                          <span className="font-bold text-[#3c3c3c] tracking-wide">
                            Client-Centric Approach:
                          </span>{" "}
                          To build long-lasting partnerships with our clients,
                          understanding their needs, and surpassing their
                          expectations through our solutions.
                        </p>
                      </li>
                      <li>
                        <p className="my-1 lg:my-3 text-justify font-light tracking-wide">
                          <span className="font-bold text-[#3c3c3c] tracking-wide">
                            Global Impact:
                          </span>{" "}
                          To make a global impact by developing software that
                          solves complex problems and improves the lives of
                          people around the world.
                        </p>
                      </li>
                      <li>
                        <p className="my-1 lg:my-3 text-justify font-light tracking-wide">
                          <span className="font-bold text-[#3c3c3c] tracking-wide">
                            Empowering Talent:
                          </span>{" "}
                          To foster a culture of continuous learning and
                          personal growth, enabling our team to reach their full
                          potential and contribute to our success.
                        </p>
                      </li>
                      <li>
                        <p className="my-1 lg:my-3 text-justify font-light tracking-wide">
                          <span className="font-bold text-[#3c3c3c] tracking-wide">
                            Sustainable Technology:
                          </span>{" "}
                          To promote environmentally sustainable practices and
                          develop software that contributes positively to
                          society and the planet.
                        </p>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}

                  {kk2 ? (
                    <ul>
                      <li>
                        <p className="text-justify font-light tracking-wide">
                          <span className="font-bold text-[#3c3c3c] tracking-wide">
                            Excellence in Development:
                          </span>{" "}
                          Strive for excellence in software development by
                          adhering to best practices, staying updated with the
                          latest technologies, and delivering high-quality,
                          reliable solutions.
                        </p>
                      </li>
                      <li>
                        <p className="my-3 text-justify font-light tracking-wide">
                          <span className="font-bold text-[#3c3c3c] tracking-wide">
                            Client Satisfaction:
                          </span>{" "}
                          Understand our client's unique needs, goals, and
                          challenges to provide tailored software solutions that
                          drive their success and satisfaction.
                        </p>
                      </li>
                      <li>
                        <p className="my-3 text-justify font-light tracking-wide">
                          <span className="font-bold text-[#3c3c3c] tracking-wide">
                            Innovation:
                          </span>{" "}
                          Encourage a culture of innovation and creativity among
                          our team members, driving continuous improvement in
                          our products and services.
                        </p>
                      </li>
                      <li>
                        <p className="my-3 text-justify font-light tracking-wide">
                          <span className="font-bold text-[#3c3c3c] tracking-wide">
                            Global Reach:
                          </span>{" "}
                          Expand our presence in the global market, delivering
                          software solutions that address diverse industries and
                          geographies.
                        </p>
                      </li>
                      <li>
                        <p className="my-3 text-justify font-light tracking-wide">
                          <span className="font-bold text-[#3c3c3c] tracking-wide">
                            Community Engagement:
                          </span>{" "}
                          Contribute to the tech community through knowledge
                          sharing, mentorship, and involvement in initiatives
                          that promote technology accessibility and inclusion.
                        </p>
                      </li>
                      <li>
                        <p className="my-3 text-justify font-light tracking-wide">
                          <span className="font-bold text-[#3c3c3c] tracking-wide">
                            Sustainability:
                          </span>{" "}
                          Embrace sustainable practices in software development,
                          reducing environmental impact and supporting social
                          responsibility initiatives.
                        </p>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid bg-[#DB0000]  relative mb-7">
        <div className="w-full">
          <div className=" container m-auto">
            <div className=" xl:px-20 lg:px-20 md:px-8 sm:px-8 px-8 text-center py-4 sm:py-4 md:py-6 lg:py-14">
              <h1 className="lg:text-2xl md:text-xl sm-text-lg my-2 sm:my-3 text-[#fff] font-bold tracking-wider">
                HOW WE WORK
              </h1>
              <p className="text-sm text-[#fff] text-center lg:text-lg md:text-base sm:text-sm pb-2 tracking-wider">
                We follow a comprehensive and enjoyable series of
                methodologies,from the pen of ideology to the method of
                action,to assist us in completing the task on time and with
                guranteed satisfaction
              </p>
            </div>
          </div>
          <div className="bg-[#fbfbfb] relative border-b-2 border-dotted border-gray-300 xl:h-40 flex justify-center">
            <div className="xl:absolute my-4 bottom-1 grid text-center grid-cols-1 gap-4 ms-4 md:grid-cols-3 sm:grid-cols-2 xl:grid-cols-6 xl:bottom-0 lg:bottom-8 md:bottom-6">
              <div className="">
                <img
                  src={png1}
                  className="h-48 w-48 xl:h-40 xl:w-40 object-contain mx-auto"
                ></img>
                <h1 className="font-semibold text-xl my-1">Info gathering</h1>
              </div>
              <div className="">
                <img
                  src={png2}
                  className="h-48 w-48 xl:h-40 xl:w-40 object-contain mx-auto"
                ></img>
                <h1 className="font-semibold text-xl my-1">Planning</h1>
              </div>
              <div className="">
                <img
                  src={png3}
                  className="h-48 w-48  xl:h-40 xl:w-40 object-contain mx-auto"
                ></img>
                <h1 className="font-semibold text-xl my-1">Design</h1>
              </div>
              <div className="">
                <img
                  src={png4}
                  className="h-48 w-48  xl:h-40 xl:w-40 object-contain mx-auto"
                ></img>
                <h1 className="font-semibold text-xl my-1">Development</h1>
              </div>
              <div className="">
                <img
                  src={png5}
                  className="h-48 w-48  xl:h-40 xl:w-40 object-contain mx-auto"
                ></img>
                <h1 className="font-semibold text-xl my-1">
                  Quality Assurance
                </h1>
              </div>
              <div className="">
                <img
                  src={png6}
                  className="h-48 w-48  xl:h-40 xl:w-40 object-contain mx-auto"
                ></img>
                <h1 className="font-semibold text-xl my-1">Deploy</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" container-fluid my-2">
        <div className="w-full">
          <div className="container m-auto">
            <div className="my-5 py-0 px-10">
              <h1 className="text-2xl my-3 text-center font-semibold text-[#3C3C3C] ">
                WHY CHOOSE US
              </h1>
              <p className="text-lg text-center my-1  text-[#4B4B4B]">
                Let's look at the advantages of partenering with Ajiva infotech
                for your digital strategy and everything you need to succeed!
              </p>
            </div>
            <div className="gap-8 py-4 xl:px-16 px-4 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 md:grid-cols-2">
              <div className="w-108 bg-[#fff] shadow-[0_0px_10px_-5px_rgba(0,0,0,0.8)] mx-auto text-center px-5 py-5 sm:py-1 sm:px-1 md:py-5 md:px-5">
                <img src={logo1} className="mx-auto my-4"></img>
                <h2 className="font-bold text-lg  text-[#3C3C3C]">
                  Dedicated Team
                </h2>
                <p className="my-2 text-center text-[#4B4B4B] lg:text-[12px] text-[10px]">
                  We have dedicated team leaders who will assist you with
                  communication and technical support throughout the project.
                  You will also receive updates on everything from
                  implementation to testing and deployment.
                </p>
              </div>
              <div className="w-108 bg-[#fff] shadow-[0_0px_10px_-5px_rgba(0,0,0,0.8)] mx-auto text-center px-5 py-5 sm:py-1 sm:px-1 md:py-5 md:px-5">
                <img src={logo2} className="mx-auto my-4"></img>
                <h2 className="font-bold text-lg text-[#3C3C3C]">
                  Timely Delivery
                </h2>
                <p className="my-2 text-center text-[#4B4B4B] lg:text-[12px] text-[10px]">
                  As a leading mobile app development company, we adhere to
                  strict app development methodology, which allows you and us to
                  achieve a great application developed on time and with
                  excellent feedback.
                </p>
              </div>
              <div className="w-108  bg-[#fff] shadow-[0_0px_10px_-5px_rgba(0,0,0,0.8)] md:col-span-2 lg:col-span-2 xl:col-span-1 mx-auto text-center px-5 py-5 md:py-5 md:px-5">
                <img src={logo3} className="mx-auto my-4"></img>
                <h2 className="font-bold text-lg text-[#3C3C3C]">
                  24*7 Support
                </h2>
                <p className="my-2 font-normal text-center text-[#4B4B4B] lg:text-[12px] text-[10px]">
                  Get solid technical solutions whenever you need them, 24 hours
                  a day, 365 days a year. Our experts are driven to dispel
                  users' doubts, questions, and issues.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="js_counter_company py-10">
      <div className="container m-auto relative">
        <div className="w-full lg:flex">
          <div className="lg:w-1/3">
            <div className="text-center">
              <h2 className="text-5xl font-bold text-red-600">
                {percentage}+
              </h2>
              <p className="text-2xl font-mediuam my-1 text-white">
                Expert Team
              </p>
            </div>
          </div>
          <div className="lg:w-1/3">
            <div className="text-center">
              <h2 className="text-5xl font-bold text-red-600">
                {percentage1}%
              </h2>
              <p className="text-2xl font-mediuam my-1 text-white">
                Happy Clients
              </p>
            </div>
          </div>
          <div className="lg:w-1/3">
            <div className="text-center">
              <h2 className="text-5xl font-bold text-red-600">
                {percentage2}
              </h2>
              <p className="text-2xl font-mediuam my-1 text-white">
                Award Achievement
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> */}
      <section className="my-16 px-5">
        <div className="container m-auto">
          <div className="w-full lg:flex">
            <div className="lg:w-1/2 relative">
              <div className="js_about-img-wrap flex">
                <img src={one} className="shape_one" alt="one" />
                <div className="js_about_img">
                  <img src={two} className="w-full" alt="two" />
                </div>
                <div className="js_about_img2">
                  <img src={three} className="w-full" alt="three" />
                </div>
              </div>
            </div>
            <div className="lg:w-1/2">
              <div className="our_services_card  lg:py-12 lg:px-6">
                <h2 className="text-red-600 my-10 text-2xl font-semibold flex pt-10 lg:pt-0">
                  <p className="h-1 my-3 mx-2 bar"></p>
                  WHAT WE DO
                </h2>
                {/* <h2 className="text-4xl font-bold pt-4 text-[#343434]">
                Highly Tailored IT Design, Management & Support Services.
              </h2> */}
                <p className="text-gray-600 text-justify font-medium text-base my-4">
                  Ajiva Infotech Pvt Ltd company specializing in a wide array of
                  services to empower your online presence. From robust web
                  development using technologies like WordPress, PHP, ReactJS,
                  NodeJS, Angular, and Shopify, to crafting stunning web designs
                  in Figma, along with top-notch graphic and logo design, we
                  elevate your brand's visual identity. Our expertise extends to
                  crafting seamless mobile apps with Flutter and Firebase, and
                  we excel in building efficient CRM systems and powerful APIs
                  to streamline your operations. Trust us to bring your digital
                  vision to life with our comprehensive web and app development
                  solutions.
                </p>
                <div className="border-b-2 border-slate-200 py-3"></div>
                {/* <div className="flex my-4">
                <div className="mr-10">
                  <h2 className="text-xl">Our Mission</h2>
                  <p className="text-base my-1 text-gray-600 font-medium">
                    Accelerate innovation with world-className tech teams. We
                    help businesses elevate their value.
                  </p>
                </div>
                <div className="ml-10">
                  <h2 className="text-xl">Custom Code</h2>
                  <p className="text-base my-1 text-gray-600 font-medium">
                    Accelerate innovation with world-className tech teams. We
                    help businesses elevate their value.
                  </p>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
